.payment-list-page {
     .status-block {
      width: 165px;
      border-radius: 15px;
      text-align: center;
      background-color: #BCDCED;
      padding: 4px 7px;
  
      &.wating {
        background-color: #C5EABF!important;
        white-space: rowrap !important;
        padding: 4px 7px;
      }
  
       &.succeed {
        background-color: #BCDCED!important;
      }

      &.error {
        background-color: #F2473E!important;
      }
      
       &.canceled {
        background-color: #D5D4D4!important;
       }

      &.refunded {
        background-color: #F4D0D5!important;
      }
    }

}
@primary-color: #06adef;